import fetch from 'auth/FetchInterceptor';
import { higherAuthFunction } from './utils';

const usersService = {}

const getGroups = async function (params, cb) {
  try {
    const res = await fetch({
      url: '/accounts/groups/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
    else return res;
  } catch (err) {
    if(cb) return cb(err, null);
    else return err
  }
}

const getUsers = async function (params, cb) {
  try {
    const res = await fetch({
      url: '/accounts/users/',
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
    else return res;
  } catch (err) {
    if(cb) return cb(err, null);
    else return err
  }
}

const addUser = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/accounts/users/',
      method: 'post',
      data
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
}

const changePassword = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/accounts/users/change-password/',
      method: 'post',
      data
    });
    if(cb) return cb(null, res);
    else return res
  } catch (err) {
    if(cb) return cb(err, null);
    else return err
  }
}

const getUserById = async function (params, cb) {
  try {
    const res = await fetch({
      url: `/accounts/users/${params.id}/`,
      method: 'get',
      params
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
}

const updateUser = async function (data, cb) {
  try {
    const res = await fetch({
    url: `/accounts/users/${data.id}/`,
    method: 'put',
    data
  });
  if(cb) return cb(null, res);
    else return res
  } catch (err) {
    if(cb) return cb(err, null);
    else return err
  }
}

const deleteUser = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/accounts/users/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res
    } catch (err) {
      if(cb) return cb(err, null);
      else return err
    }
}

//Groups
usersService.getGroups = (params, cb) => higherAuthFunction(getGroups, params, cb);

//Users
usersService.getUsers = (params, cb) => higherAuthFunction(getUsers, params, cb);
usersService.addUser = (data, cb) => higherAuthFunction(addUser, data, cb);
usersService.updateUser = (data, cb) => higherAuthFunction(updateUser, data, cb);
usersService.deleteUser = (data, cb) => higherAuthFunction(deleteUser, data, cb);

// User
usersService.changePassword = (data, cb) => higherAuthFunction(changePassword, data, cb);

export default usersService