import fetch from 'auth/FetchInterceptor';
import { higherAuthFunction } from './utils';

const xncoSaleService = {}

// Categories
const getCategories = async function (params,cb) {
  try {
    const res = await fetch({
    url: '/sale/product-categories/',
    method: 'get',
    params
  })
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addCategory = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/product-categories/',
      method: 'post',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateCategory = async function (data, cb) {
  try {
    const res = await fetch({
    url: `/sale/product-categories/${data.id}/`,
    method: 'put',
    data
  });
  if(cb) return cb(null, res);
    else return res;
  } catch (err) {
    if(cb) return cb(err, null);
    else return err;
  }
}

const deleteCategory = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/product-categories/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Sites
const getSites = async function (params,cb) {
  try {
    const res = await fetch({
    url: '/sale/sites/',
    method: 'get',
    params
  })
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addSite = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/sites/',
      method: 'post',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateSite = async function (data, cb) {
  try {
    const res = await fetch({
    url: `/sale/sites/${data.id}/`,
    method: 'put',
    data
  });
  if(cb) return cb(null, res);
    else return res;
  } catch (err) {
    if(cb) return cb(err, null);
    else return err;
  }
}

const deleteSite = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/sites/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Markets
const getMarkets = async function (params,cb) {
  try {
    const res = await fetch({
    url: '/sale/markets/',
    method: 'get',
    params
  })
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addMarket = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/markets/',
      method: 'post',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateMarket = async function (data, cb) {
  try {
    const res = await fetch({
    url: `/sale/markets/${data.id}/`,
    method: 'put',
    data
  });
  if(cb) return cb(null, res);
    else return res;
  } catch (err) {
    if(cb) return cb(err, null);
    else return err;
  }
}

const deleteMarket = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/markets/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Customers
const getCustomers = async function (params, cb) {
  try {
    const res = await fetch({
    url: '/sale/customers/',
    method: 'get',
    params
  })
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addCustomer = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/customers/',
      method: 'post',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateCustomer = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/customers/${data.id}/`,
      method: 'put',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const deleteCustomer = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/customers/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Discount Products
const getDiscountProducts = async function (params,cb) {
  try {
    const res = await fetch({
    url: '/sale/discount-products/',
    method: 'get',
    params
  })
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addDiscountProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/discount-products/',
      method: 'post',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateDiscountProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/discount-products/${data.id}/`,
      method: 'put',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const deleteDiscountProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/discount-products/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Invoices
const getInvoices = async function (params,cb) {
  try {
    const res = await fetch({
    url: '/sale/invoices/',
    method: 'get',
    params
  })
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addInvoice = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/invoices/',
      method: 'post',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateInvoice = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/invoices/${data.id}/`,
      method: 'put',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const deleteInvoice = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/invoices/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Saler Stock Availability
const getSalerStockAvailabilities = async function (params,cb) {
  try {
    const res = await fetch({
    url: '/sale/saler-stock-availabilities/',
    method: 'get',
    params
  })
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addSalerStockAvailability = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/saler-stock-availabilities/',
      method: 'post',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateSalerStockAvailability = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/saler-stock-availabilities/${data.id}/`,
      method: 'put',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const deleteSalerStockAvailability = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/saler-stock-availabilities/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Market Reports
const getMarketReports = async function (params,cb) {
  try {
    const res = await  fetch({
    url: '/sale/market-reports/',
    method: 'get',
    params
  })
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addMarketReport = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/market-reports/',
      method: 'post',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateMarketReport = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/market-reports/${data.id}/`,
      method: 'put',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const deleteMarketReport = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/market-reports/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Product Returns
const getProductReturns = async function (params,cb) {
  try {
    const res = await fetch({
    url: '/sale/product-returns/',
    method: 'get',
    params
  })
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addProductReturn = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/product-returns/',
      method: 'post',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateProductReturn = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/product-returns/${data.id}/`,
      method: 'put',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const deleteProductReturn = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/product-returns/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Invoice Products
const getInvoiceProducts = async function (params,cb) {
  try {
    const res = await fetch({
    url: '/sale/invoice-products/',
    method: 'get',
    params
  })
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addInvoiceProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/invoice-products/',
      method: 'post',
      data
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

const updateInvoiceProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/invoice-products/${data.id}/`,
      method: 'put',
      data
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const deleteInvoiceProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/invoice-products/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Salers
const getSalers = async function (params, cb) {
  try {
    const res = await fetch({
    url: '/sale/salers/',
    method: 'get',
    params
  });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addSaler = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/salers/',
      method: 'post',
      data,
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateSaler = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/salers/${data.id}/`,
      method: 'put',
      data,
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const deleteSaler = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/salers/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Products
const getProducts = async function (params,cb) {
  try {
    const res = await fetch({
    url: '/sale/products/',
    method: 'get',
    params
  });
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/products/',
      method: 'post',
      data,
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/products/${data.id}/`,
      method: 'put',
      data,
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const deleteProduct = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/products/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// Discounts
const getDiscounts = async function (params,cb) {
  try {
    const res = await fetch({
    url: '/sale/discounts/',
    method: 'get',
    params
  });
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addDiscount = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/discounts/',
      method: 'post',
      data,
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateDiscount = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/discounts/${data.id}/`,
      method: 'put',
      data,
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const deleteDiscount = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/discounts/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// NewOnMarkets
const getNewOnMarket = async function (params,cb) {
  try {
    const res = await fetch({
    url: '/sale/new-on-market/',
    method: 'get',
    params
  });
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addNewOnMarket = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/new-on-market/',
      method: 'post',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

const updateNewOnMarket = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/new-on-market/${data.id}/`,
      method: 'put',
      data,
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

const deleteNewOnMarket = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/new-on-market/${data.id}/`,
      method: 'delete',
    });
    cb(null, res);
  } catch (e) {
    cb(e, null);
  }
}

// Departments
const getDepartments = async function (params,cb) {
  try {
    const res = await  fetch({
    url: '/sale/departments/',
    method: 'get',
    params
  });
  if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const addDepartment = async function (data, cb) {
  try {
    const res = await fetch({
      url: '/sale/departments/',
      method: 'post',
      data,
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const updateDepartment = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/departments/${data.id}/`,
      method: 'put',
      data,
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

const deleteDepartment = async function (data, cb) {
  try {
    const res = await fetch({
      url: `/sale/departments/${data.id}/`,
      method: 'delete',
    });
    if(cb) return cb(null, res);
      else return res;
    } catch (err) {
      if(cb) return cb(err, null);
      else return err;
    }
}

// const requestHandler = async function (requestConfig, cb) {
//   try {
//     const res = await fetch(requestConfig);
//     if (cb) return cb(null, res);
//     else return res;
//   } catch (err) {
//     if (cb) return cb(err, null);
//     else return err;
//   }
// };

// xncoSaleService.getCategories = (params, cb) => 
//   higherAuthFunction(requestHandler.bind(null, { url: '/sale/product-categories/', method: 'get', params }, cb), params, cb);

xncoSaleService.getCategories = (params, cb) => higherAuthFunction(getCategories, params, cb);
xncoSaleService.addCategory = (data, cb) => higherAuthFunction(addCategory, data, cb);
xncoSaleService.updateCategory = (data, cb) => higherAuthFunction(updateCategory, data, cb);
xncoSaleService.deleteCategory = (data, cb) => higherAuthFunction(deleteCategory, data, cb);

xncoSaleService.getSites = (params, cb) => higherAuthFunction(getSites, params, cb);
xncoSaleService.addSite = (data, cb) => higherAuthFunction(addSite, data, cb);
xncoSaleService.updateSite = (data, cb) => higherAuthFunction(updateSite, data, cb);
xncoSaleService.deleteSite = (data, cb) => higherAuthFunction(deleteSite, data, cb);

xncoSaleService.getMarkets = (params, cb) => higherAuthFunction(getMarkets, params, cb);
xncoSaleService.addMarket = (data, cb) => higherAuthFunction(addMarket, data, cb);
xncoSaleService.updateMarket = (data, cb) => higherAuthFunction(updateMarket, data, cb);
xncoSaleService.deleteMarket = (data, cb) => higherAuthFunction(deleteMarket, data, cb);

xncoSaleService.getCustomers = (params, cb) => higherAuthFunction(getCustomers, params, cb);
xncoSaleService.addCustomer = (data, cb) => higherAuthFunction(addCustomer, data, cb);
xncoSaleService.updateCustomer = (data, cb) => higherAuthFunction(updateCustomer, data, cb);
xncoSaleService.deleteCustomer = (data, cb) => higherAuthFunction(deleteCustomer, data, cb);

xncoSaleService.getDiscountProducts = (params, cb) => higherAuthFunction(getDiscountProducts, params, cb);
xncoSaleService.addDiscountProduct = (data, cb) => higherAuthFunction(addDiscountProduct, data, cb);
xncoSaleService.updateDiscountProduct = (data, cb) => higherAuthFunction(updateDiscountProduct, data, cb);
xncoSaleService.deleteDiscountProduct = (data, cb) => higherAuthFunction(deleteDiscountProduct, data, cb);

xncoSaleService.getInvoices = (params, cb) => higherAuthFunction(getInvoices, params, cb);
xncoSaleService.addInvoice = (data, cb) => higherAuthFunction(addInvoice, data, cb);
xncoSaleService.updateInvoice = (data, cb) => higherAuthFunction(updateInvoice, data, cb);
xncoSaleService.deleteInvoice = (data, cb) => higherAuthFunction(deleteInvoice, data, cb);

xncoSaleService.getSalerStockAvailabilities = (params, cb) => higherAuthFunction(getSalerStockAvailabilities, params, cb);
xncoSaleService.addSalerStockAvailability = (data, cb) => higherAuthFunction(addSalerStockAvailability, data, cb);
xncoSaleService.updateSalerStockAvailability = (data, cb) => higherAuthFunction(updateSalerStockAvailability, data, cb);
xncoSaleService.deleteSalerStockAvailability = (data, cb) => higherAuthFunction(deleteSalerStockAvailability, data, cb);

xncoSaleService.getMarketReports = (params, cb) => higherAuthFunction(getMarketReports, params, cb);
xncoSaleService.addMarketReport = (data, cb) => higherAuthFunction(addMarketReport, data, cb);
xncoSaleService.updateMarketReport = (data, cb) => higherAuthFunction(updateMarketReport, data, cb);
xncoSaleService.deleteMarketReport = (data, cb) => higherAuthFunction(deleteMarketReport, data, cb);

xncoSaleService.getProductReturns = (params, cb) => higherAuthFunction(getProductReturns, params, cb);
xncoSaleService.addProductReturn = (data, cb) => higherAuthFunction(addProductReturn, data, cb);
xncoSaleService.updateProductReturn = (data, cb) => higherAuthFunction(updateProductReturn, data, cb);
xncoSaleService.deleteProductReturn = (data, cb) => higherAuthFunction(deleteProductReturn, data, cb);

xncoSaleService.getInvoiceProducts = (params, cb) => higherAuthFunction(getInvoiceProducts, params, cb);
xncoSaleService.addInvoiceProduct = (data, cb) => higherAuthFunction(addInvoiceProduct, data, cb);
xncoSaleService.updateInvoiceProduct = (data, cb) => higherAuthFunction(updateInvoiceProduct, data, cb);
xncoSaleService.deleteInvoiceProduct = (data, cb) => higherAuthFunction(deleteInvoiceProduct, data, cb);

xncoSaleService.getSalers = (params, cb) => higherAuthFunction(getSalers, params, cb);
xncoSaleService.addSaler = (data, cb) => higherAuthFunction(addSaler, data, cb);
xncoSaleService.updateSaler = (data, cb) => higherAuthFunction(updateSaler, data, cb);
xncoSaleService.deleteSaler = (data, cb) => higherAuthFunction(deleteSaler, data, cb);

xncoSaleService.getProducts = (params, cb) => higherAuthFunction(getProducts, params, cb);
xncoSaleService.addProduct = (data, cb) => higherAuthFunction(addProduct, data, cb);
xncoSaleService.updateProduct = (data, cb) => higherAuthFunction(updateProduct, data, cb);
xncoSaleService.deleteProduct = (data, cb) => higherAuthFunction(deleteProduct, data, cb);

// xncoSaleService.getProductCategories = (params, cb) => higherAuthFunction(getProductCategories, params, cb);
// xncoSaleService.addProductCategory = (data, cb) => higherAuthFunction(addProductCategory, data, cb);
// xncoSaleService.updateProductCategory = (data, cb) => higherAuthFunction(updateProductCategory, data, cb);
// xncoSaleService.deleteProductCategory = (data, cb) => higherAuthFunction(deleteProductCategory, data, cb);

xncoSaleService.getDiscounts = (params, cb) => higherAuthFunction(getDiscounts, params, cb);
xncoSaleService.addDiscount = (data, cb) => higherAuthFunction(addDiscount, data, cb);
xncoSaleService.updateDiscount = (data, cb) => higherAuthFunction(updateDiscount, data, cb);
xncoSaleService.deleteDiscount = (data, cb) => higherAuthFunction(deleteDiscount, data, cb);

xncoSaleService.getNewOnMarket = (params, cb) => higherAuthFunction(getNewOnMarket, params, cb);
xncoSaleService.addNewOnMarket = (data, cb) => higherAuthFunction(addNewOnMarket, data, cb);
xncoSaleService.updateNewOnMarket = (data, cb) => higherAuthFunction(updateNewOnMarket, data, cb);
xncoSaleService.deleteNewOnMarket = (data, cb) => higherAuthFunction(deleteNewOnMarket, data, cb);

xncoSaleService.getDepartments = (params, cb) => higherAuthFunction(getDepartments, params, cb);
xncoSaleService.addDepartment = (data, cb) => higherAuthFunction(addDepartment, data, cb);
xncoSaleService.updateDepartment = (data, cb) => higherAuthFunction(updateDepartment, data, cb);
xncoSaleService.deleteDepartment = (data, cb) => higherAuthFunction(deleteDepartment, data, cb);

export default xncoSaleService