export const AUTH_TOKEN = 'auth_token'
export const REFRESH_TOKEN = 'refresh_token'
export const USER_INFO = 'user_info'

//modules
export const DEKAXNCO_SALE_MODULE = 'dekaxnco_sale'
export const VESSEL_DISPATCHING_MODULE = 'vessel_dispatching'
export const ACCOUNTS_MODULE = 'accounts'

//actions
export const CHANGE_ACTION = 'change'
export const VIEW_ACTION = 'view'
export const DELETE_ACTION = 'delete'
export const ADD_ACTION = 'add'