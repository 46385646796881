import store from "../../../store";

const HasPerm = ({ module, action, children, entity, ...rest }) => {
	const { user: { permissions } } = store.getState().auth;

    //Allow view a whole module. Ex.: Nav use
    if (module && action && entity === undefined){
        const can_access_module = permissions?.find(perm => perm.includes(`${module}.${action}`));
        return Boolean(can_access_module);
    }

    return Boolean(permissions?.find(perm => perm === `${module}.${action}_${entity}`)) && (children || true);
}

export default HasPerm;