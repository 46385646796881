import fetch from 'auth/FetchInterceptor'
import { refreshToken, signOutSuccess } from 'store/slices/authSlice';

import { AUTH_TOKEN, REFRESH_TOKEN } from 'constants/AuthConstant';

import store from 'store';

import { notification } from 'antd';

const showErrorNotification = (message, description) => {
    let notificationParam = { message: '' };

    notificationParam.message = message;
    notificationParam.description = description;
    
    notification.error(notificationParam);
};

export const higherAuthFunction = (request, data, cb) => {

    const refreshAccess = async function (_cb) {
        const { refeshToken: refresh } = store.getState().auth;

        try {
            const res = await fetch({
                url: '/token/refresh/',
                method: 'post',
                data: { refresh }
            });
    
            store.dispatch(refreshToken(res.access));
            localStorage.setItem(AUTH_TOKEN, res.access);
    
            if(_cb) return _cb(null, res);
            else return res;
        } catch (err) {
            if(err.response.status === 401){
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(REFRESH_TOKEN);
    
                store.dispatch(signOutSuccess());
                showErrorNotification('Authentication Fail','Please login again');
    
                if(_cb) return _cb(err, null);
                else return err;
            }
            else{
                showErrorNotification('Connexion Error','Please check your connexion');
    
                if(process.env.NODE_ENV !== 'production'){
                    if(err.response){
                        console.log(JSON.stringify(err.response));
                    }
                    if(err.request){
                        console.log(JSON.stringify(err.request));
                    }
                }
    
                if(_cb) return _cb(err, null);
                else return err;
            }
        }
    }

    const higherAuthCb = (e, r) => {
        if(e){
            if(e.response?.status === 401){
                return refreshAccess((err, res) => {
                    if(err){
                        if(cb) return cb(e, r);
                        else return e;
                    }
                    else{
                        return request(data, cb);
                    }
                });
            }
            else{
                showErrorNotification('Connexion Error','Please check your connexion');
    
                if(process.env.NODE_ENV !== 'production'){
                    if(e.response){
                        console.log(JSON.stringify(e.response));
                    }
                    if(e.request){
                        console.log(JSON.stringify(e.request));
                    }
                }

                if(cb) return cb(e, r);
                else return e;
            }
        } 
        else{
            if(cb) return cb(e, r);
            else return r;
        }
    };

    return request(data, higherAuthCb);
};