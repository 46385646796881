import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import VesselServices from 'services/VesselServices';

// Clients
export const clientsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	clients: [],
  }
  
  export const getClients = createAsyncThunk('vessel/clients', async (data, { rejectWithValue }) => {
	try {
	  const response = await VesselServices.getClients(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const vesselClientsSlice = createSlice({
	name: 'vessel-clients',
	initialState: clientsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getClients.pending, (state) => {
		  state.loading = true;
		})
		.addCase(getClients.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getClients.fulfilled, (state, action) => {
		  state.clients = action.payload;
		  state.loading = false;
		});
	},
  });

// Drivers
export const driversInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	drivers: [],
  }
  
  export const getDrivers = createAsyncThunk('vessel/drivers', async (data, { rejectWithValue }) => {
	try {
	  const response = await VesselServices.getDrivers(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const vesselDriversSlice = createSlice({
	name: 'vessel-drivers',
	initialState: driversInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getDrivers.pending, (state) => {
		  state.loading = true;
		})
		.addCase(getDrivers.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getDrivers.fulfilled, (state, action) => {
		  state.drivers = action.payload;
		  state.loading = false;
		});
	},
  });

// Ports
export const portsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	ports: [],
  }
  
  export const getPorts = createAsyncThunk('vessel/ports', async (data, { rejectWithValue }) => {
	try {
	  const response = await VesselServices.getPorts(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const vesselPortsSlice = createSlice({
	name: 'vessel-ports',
	initialState: portsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showPortsLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getPorts.pending, (state) => {
		  state.loading = true;
		})
		.addCase(getPorts.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getPorts.fulfilled, (state, action) => {
		  state.ports = action.payload;
		  state.loading = false;
		});
	},
  });

// Tasks
export const tasksInitialState = {
	loading: true,
	message: '',
	showMessage: false,
	tasks: [],
  }
  
  export const getTasks = createAsyncThunk('vessel/tasks', async (data, { rejectWithValue }) => {
	try {
	  const response = await VesselServices.getTasks(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const vesselTasksSlice = createSlice({
	name: 'vessel-tasks',
	initialState: tasksInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	  resetTasks: state => {
		state.loading = true;
		state.tasks = [];
		state.message = '';
		state.showMessage = false;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getTasks.pending, (state) => {
		  state.loading = true;
		})
		.addCase(getTasks.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getTasks.fulfilled, (state, action) => {
		  state.tasks = action.payload;
		  state.loading = false;
		});
	},
  });

// Vehicles
export const vehiclesInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	vehicles: [],
  }
  
  export const getVehicles = createAsyncThunk('vessel/vehicles', async (data, { rejectWithValue }) => {
	try {
	  const response = await VesselServices.getVehicles(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const vesselVehiclesSlice = createSlice({
	name: 'vessel-vehicles',
	initialState: vehiclesInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getVehicles.pending, (state) => {
		  state.loading = true;
		})
		.addCase(getVehicles.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getVehicles.fulfilled, (state, action) => {
		  state.vehicles = action.payload;
		  state.loading = false;
		});
	},
  });

// Ship Schedules
export const shipSchedulesInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	shipSchedules: [],
  }
  
  export const getShipSchedules = createAsyncThunk('vessel/shipSchedules', async (data, { rejectWithValue }) => {
	try {
	  const response = await VesselServices.getShipSchedules(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const vesselShipSchedulesSlice = createSlice({
	name: 'vessel-shipSchedules',
	initialState: shipSchedulesInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getShipSchedules.pending, (state) => {
		  state.loading = true;
		})
		.addCase(getShipSchedules.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getShipSchedules.fulfilled, (state, action) => {
		  state.shipSchedules = action.payload;
		  state.loading = false;
		});
	},
  });

// Ships
export const shipsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	ships: [],
  }
  
  export const getShips = createAsyncThunk('vessel/ships', async (data, { rejectWithValue }) => {
	try {
	  const response = await VesselServices.getShips(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const vesselShipsSlice = createSlice({
	name: 'vessel-ships',
	initialState: shipsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getShips.pending, (state) => {
		  state.loading = true;
		})
		.addCase(getShips.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getShips.fulfilled, (state, action) => {
		  state.ships = action.payload;
		  state.loading = false;
		});
	},
  });

// Pointers
export const pointersInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	pointers: [],
  }
  
  export const getPointers = createAsyncThunk('vessel/pointers', async (data, { rejectWithValue }) => {
	try {
	  const response = await VesselServices.getPointers(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const vesselPointersSlice = createSlice({
	name: 'vessel-pointers',
	initialState: pointersInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getPointers.pending, (state) => {
		  state.loading = true;
		})
		.addCase(getPointers.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getPointers.fulfilled, (state, action) => {
		  state.pointers = action.payload;
		  state.loading = false;
		});
	},
  });

// Products
export const vesselProductsInitialState = {
	loading: false,
	message: '',
	showMessage: false,
	products: [],
  }
  
  export const getProducts = createAsyncThunk('vessel/products', async (data, { rejectWithValue }) => {
	try {
	  const response = await VesselServices.getProducts(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const vesselProductsSlice = createSlice({
	name: 'vessel-products',
	initialState: vesselProductsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	},
	extraReducers: builder => {
	  builder
		.addCase(getProducts.pending, (state) => {
		  state.loading = true;
		})
		.addCase(getProducts.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getProducts.fulfilled, (state, action) => {
		  state.products = action.payload;
		  state.loading = false;
		});
	},
  });

// Task Details
export const taskDetailsInitialState = {
	loading: true,
	message: '',
	showMessage: false,
	taskDetails: [],
  }
  
  export const getTaskDetails = createAsyncThunk('vessel/taskDetails', async (data, { rejectWithValue }) => {
	try {
	  const response = await VesselServices.getTaskDetails(data);
	  return response;
	} catch (err) {
	  return rejectWithValue(err.response?.data?.message || 'Error');
	}
  });
  
  export const vesselTaskDetailsSlice = createSlice({
	name: 'vessel-taskDetails',
	initialState: taskDetailsInitialState,
	reducers: {
	  showRequestMessage: (state, action) => {
		state.message = action.payload;
		state.showMessage = true;
		state.loading = false;
	  },
	  hideRequestMessage: state => {
		state.message = '';
		state.showMessage = false;
	  },
	  showLoading: state => {
		state.loading = true;
	  },
	  resetTaskDetails: state => {
		state.loading = true
		state.message = ''
		state.showMessage = false
		state.taskDetails = []
	  }
	},
	extraReducers: builder => {
	  builder
		.addCase(getTaskDetails.pending, (state) => {
		  state.loading = true;
		})
		.addCase(getTaskDetails.rejected, (state, action) => {
		  state.message = action.payload;
		  state.showMessage = true;
		  state.loading = false;
		})
		.addCase(getTaskDetails.fulfilled, (state, action) => {
		  state.taskDetails = action.payload;
		  state.loading = false;
		});
	},
  });


  export const vesselClients = vesselClientsSlice.reducer;
  export const vesselDrivers = vesselDriversSlice.reducer;
  export const vesselPorts = vesselPortsSlice.reducer;
  export const vesselTasks = vesselTasksSlice.reducer;
  export const vesselVehicles = vesselVehiclesSlice.reducer;
  export const vesselShipSchedules = vesselShipSchedulesSlice.reducer;
  export const vesselShips = vesselShipsSlice.reducer;
  export const vesselPointers = vesselPointersSlice.reducer;
  export const vesselProducts = vesselProductsSlice.reducer;
  export const vesselTaskDetails = vesselTaskDetailsSlice.reducer;

export const {
	showRequestMessage,
	hideRequestMessage,
	showPortsLoading,
} = vesselPortsSlice.actions

export const {
	resetTasks
} = vesselTasksSlice.actions

export const {
	resetTaskDetails
} = vesselTaskDetailsSlice.actions
